.authenticated-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-nav {
  background-color: var(--color-background);
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-sm) 0;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.nav-links {
  display: flex;
  gap: var(--spacing-md);
}

.nav-button, .logout-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
}

.nav-button {
  color: var(--color-text);
  background-color: transparent;
}

.nav-button:hover, .nav-button.active {
  background-color: var(--color-primary);
  color: var(--color-background);
}

.logout-button {
  color: var(--color-background);
  background-color: var(--color-primary);
  border: none;
  cursor: pointer;
}

.logout-button:hover {
  background-color: var(--color-primary-dark);
}

.main-content {
  flex: 1;
  padding: var(--spacing-xl) 0;
}

@media (max-width: 768px) {
  .nav-content {
    flex-direction: column;
    align-items: stretch;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    margin-bottom: var(--spacing-sm);
  }

  .nav-button, .logout-button {
    width: 100%;
    text-align: center;
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-xs);
  }

  .main-content {
    padding: var(--spacing-lg) 0;
  }
}
