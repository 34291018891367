:root {
  /* Colors */
  --color-primary: #4285F4;
  --color-primary-dark: #3367D6;
  --color-secondary: #34A853;
  --color-accent: #FBBC05;
  --color-text: #202124;
  --color-text-secondary: #5F6368;
  --color-background: #FFFFFF;
  --color-background-secondary: #F8F9FA;
  --color-border: #DADCE0;
  --color-error: #EA4335;
  --color-success: #34A853;

  /* Typography */
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-xxl: 1.5rem;
  --line-height: 1.5;

  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Box Shadows */
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-md);
  line-height: var(--line-height);
  color: var(--color-text);
  background-color: var(--color-background-secondary);
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--spacing-md);
  font-weight: 600;
}

h1 { font-size: var(--font-size-xxl); }
h2 { font-size: var(--font-size-xl); }
h3 { font-size: var(--font-size-lg); }

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button, .button {
  display: inline-block;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-md);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover, .button:hover {
  background-color: var(--color-primary-dark);
}

input, textarea, select {
  width: 100%;
  padding: var(--spacing-sm);
  font-size: var(--font-size-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--color-background);
}

input:focus, textarea:focus, select:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.error-message {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

.success-message {
  color: var(--color-success);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

@media (max-width: 768px) {
  :root {
    --font-size-sm: 0.8125rem;
    --font-size-md: 0.9375rem;
    --font-size-lg: 1rem;
    --font-size-xl: 1.125rem;
    --font-size-xxl: 1.25rem;
  }

  .container {
    padding: 0 var(--spacing-sm);
  }
}
