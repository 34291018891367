.business-profile-container {
  display: flex;
  justify-content: center;
  padding: var(--spacing-lg);
}

.business-profile-card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 600px;
}

.business-profile-title {
  font-size: var(--font-size-xxl);
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.business-profile-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
}

.form-group input {
  width: 100%;
}

.form-actions {
  margin-top: var(--spacing-lg);
}

.update-profile-button {
  width: 100%;
}

@media (max-width: 768px) {
  .business-profile-container {
    padding: var(--spacing-md);
  }

  .business-profile-card {
    padding: var(--spacing-lg);
  }

  .business-profile-title {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 480px) {
  .form-group input {
    font-size: 16px; /* Prevents auto-zoom on iOS */
  }
}
