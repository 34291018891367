.unauthenticated-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
}

.app-header {
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--color-border);
}

.app-title {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin: 0;
  padding: 0;
}

.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-md);
}

.auth-container {
  width: 100%;
  max-width: 400px;
}

@media (max-width: 768px) {
  .app-header {
    padding: var(--spacing-xs) 0;
  }

  .app-title {
    font-size: var(--font-size-lg);
  }

  .main-content {
    padding-top: var(--spacing-sm);
  }
}
