.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-md);
}

.post {
  width: 100%;
  max-width: 600px;
  margin-bottom: var(--spacing-xl);
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
}

.post img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.post-content {
  padding: var(--spacing-xl);
}

.post p {
  margin: 0;
  padding: var(--spacing-md);
  font-size: var(--font-size-md);
  color: var(--color-text);
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-sm);
}

.feedback-section {
  width: 100%;
  max-width: 600px;
  margin-top: var(--spacing-lg);
}

.feedback-textarea {
  width: 100%;
  min-height: 100px;
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-md);
  font-size: var(--font-size-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  resize: vertical;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
}

.actions button {
  flex: 1;
}

@media (max-width: 768px) {
  .dashboard {
    padding: var(--spacing-sm);
  }

  .post, .feedback-section {
    max-width: 100%;
  }

  .post-content {
    padding: var(--spacing-lg);
  }

  .post p {
    padding: var(--spacing-sm);
  }

  .actions {
    flex-direction: column;
  }

  .actions button {
    width: 100%;
    margin-bottom: var(--spacing-sm);
  }
}
