.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: var(--spacing-md);
}

.auth-card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 400px;
}

.auth-title {
  font-size: var(--font-size-xxl);
  text-align: center;
  margin-bottom: var(--spacing-xs);
}

.auth-subtitle {
  font-size: var(--font-size-lg);
  text-align: center;
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-lg);
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form .form-group {
  margin-bottom: var(--spacing-md);
}

.auth-form label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
}

.auth-form input {
  width: 100%;
}

.auth-button {
  margin-top: var(--spacing-md);
}

.auth-link {
  text-align: center;
  margin-top: var(--spacing-lg);
  font-size: var(--font-size-sm);
}

@media (max-width: 480px) {
  .auth-card {
    padding: var(--spacing-lg);
  }

  .auth-title {
    font-size: var(--font-size-xl);
  }

  .auth-subtitle {
    font-size: var(--font-size-md);
  }
}
